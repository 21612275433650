import { Box, SxProps } from '@mui/material';
import useResponsive from '#/lib/hooks/useResponsive';

export default function ContentContainer({
  children,
  sx,
}: {
  children: React.ReactNode;
  sx?: SxProps;
}) {
  const isMobile = useResponsive('down', 'md');

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      maxWidth={1264}
      margin="0 auto"
      alignSelf="center"
      sx={{
        gap: 10,
        ...(isMobile && {
          mx: 2,
        }),
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
