import { Box, Typography } from '@mui/material';
import useLocales from '#/lib/hooks/useLocales';
import IconCard from '../icon-cards';
import { WhyUsCardProps } from './types';

type WhyIsImportantProps = {
  title: string;
  cards: WhyUsCardProps[];
};

export default function WhyIsImportant({ title, cards }: WhyIsImportantProps) {
  const { translate } = useLocales();

  return (
    <Box>
      <Typography textAlign="center" variant="h2" data-aos="fade-down">
        {translate(title)}
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            md: 'repeat(3, 1fr)',
          },
          columnGap: 6,
          rowGap: 5,
        }}
      >
        {cards.map((item, i) => (
          <IconCard {...item} key={i} />
        ))}
      </Box>
    </Box>
  );
}
